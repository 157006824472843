import React from "react";
import casperLogo from '../images/log-12.png';
function Header() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="logo">
          <img
            src={casperLogo}
            alt="casper-logo.png"
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  );
}
export default Header;
