import React, { useEffect } from "react";
// import "./App.css";
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
// import RedemptionForm from "./components/RedemptionForm";
// import UserformSurvey from "./components/UserformSurvey";
// import UserForm from "./components/UserForm";
// import Thankyou from "./components/Thankyou";
// import Index from "./components/Index";
// import CertDetails from "./components/CertDetails";
import NoInternet from "./components/NoInternet";
import RegisterForm from "./components/RegisterForm";
import TestOurTech_thankyou from "./components/TestOurTech_thankyou";

function App() {
  useEffect(() => {
    localStorage.setItem("isVoucher", false);
  }, []);
  if (process.env.REACT_APP_STAGE === "PROD")
    console.log = function no_console() {};
  return (
    //FIXME UserformSurvey -> survey-form, UserForm -> user-form, Thankyou -> redemption-thankyou, TestOurTech_thankyou -> registration-thankyou
   <NoInternet>
   <Routes>
      {/* <Route path="/" element={<RedemptionForm />} /> */}
      {/* <Route path="/survey-form" element={<UserformSurvey />} /> */}
      {/* <Route path="/user-form" element={<UserForm />} /> */}
      {/* <Route path="/redemption-thankyou" element={<Thankyou />} /> */}
      {/* <Route path="/register/" element={<Index />} /> */}
      {/* <Route path="/certificate-details" element={<CertDetails />} /> */}
      <Route path="/" element={<RegisterForm />} />
      <Route
        path="/register/registration-thankyou"
        element={<TestOurTech_thankyou />}
      />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    </NoInternet>
  
  );
}

export default App;
